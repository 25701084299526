export function showMassage(massageText, color, position, speed, duration) {
    document.getElementById(position).style.backgroundColor = color;
    document.getElementById(position).innerHTML = massageText;
    let parameters = {
        duration: duration,
        complete: function () {
            $('#progress')
                .css('width', '0%')
                .text('0%');
        }
    }
    $(`#${position}`).slideDown(parameters);
    setTimeout(function () {
        parameters = {
            duration: duration,
            complete: function () {
                $('#progress')
                    .css('width', '0%')
                    .text('0%');
            }
        }
        $('#' + position).slideUp(parameters);
    }, speed);
}